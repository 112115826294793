export function minMaxLength(text, minLength, maxLength) {
  if (minLength === 'undefined') minLength = '0'

  if (maxLength === 'undefined') maxLength = '0'

  let result = !text || text.length < minLength
  if (maxLength) result = result || text.length < minLength
  return result
}

export function validEmail(text) {
  const regex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  )

  return !regex.test(text)
}

export function vaildObject(obj) {
  if (obj === undefined && typeof window !== 'undefined') {
    window.location.pathname = '/home'
  }
}

export function validArray(obj) {
  if ((obj === undefined || obj.length > 0) && typeof window !== 'undefined') {
    window.location.pathname = '/home'
  }
}

export function handleChange(
  e,
  formErrors,
  setRegistrationFormData,
  setFormErrors
) {
  // console.log(e.target.name);
  const { name, value, minLength, maxLength, type } = e.target
  let currentFormErrors = formErrors
  console.log(formErrors)

  console.log('MinLength==>' + minLength)
  console.log('name==>' + name)
  console.log('value==>' + value)
  console.log('type==>' + type)

  if (type === 'text') {
    if (!value || minMaxLength(value, minLength)) {
      currentFormErrors[name] = {
        msg: 'Should have minimum 3 characters',
        valid: 'true',
      }
    } else {
      delete currentFormErrors[name]
    }
  }

  setFormErrors(currentFormErrors)
  console.log(currentFormErrors)
}
