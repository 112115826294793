import React from "react";
import { MDBBtn, MDBIcon } from "mdbreact";
import '../button.css'


export default function CButton({ fieldData }) {
    const { value } = fieldData;

    return (

        <div className="text-center mt-4">
            {/* <MDBBtn {...fieldData} outline>
                {fieldData.value}
                {fieldData.type == "submit" &&

                    <MDBIcon far icon="paper-plane" className="ml-2" />}
            </MDBBtn> */}
            <button type="submit" class="btn btn-success contactbtn" 
            style={{backgroundColor:"rgb(0, 128, 0)",
                borderRadius:"7px",
                fontSize:"16px",
                width:"22%"}}>Submit</button>
        </div>
    )


}
