import React, { useState, useEffect } from 'react';
import Layout from '../components/layout';
import {
  MDBContainer,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCardTitle,
} from 'mdbreact';
import CButton from '../components/widgets/CButton';
import { graphql } from 'gatsby';
import { BUTTON, INPUT, LABEL, TITLELABLE, CAPTCHA, AXIOSLINK } from '../utils/constants';
import CInput from '../components/widgets/CInput';
import CaptchaComp from '../components/widgets/CaptchaComp';
import '../assets/main.css';
import emailjs from 'emailjs-com';
import { minMaxLength, validEmail } from '../components/utils/validations';
import axios from 'axios';
import Swal from "sweetalert2"
import eData from '../utils/emailkey';

function Contact(contactData, location) {
  const { data } = contactData
  const { allStrapiContacts } = data
  const [verify, setVerify] = useState(false)
  const [load, setLoad] = useState({ loadFunc: () => {} })

  const errorDefaultData = {
    name: {
      msg: 'Name should have minimum 3 characters',
      valid: 'false',
    },
    emailAddress: {
      msg: 'Email address is invalid',
      valid: 'false',
    },
    comment: {
      msg: 'Comment should have minimum 20 characters',
      valid: 'false',
    },
    subject: {
      msg: 'Subject should have minimum 5 characters',
      valid: 'false',
    },
  }

  const [user, setUser] = useState({})
  const [formErrors, setFormErrors] = useState(errorDefaultData)

  useEffect(() => {
    setFormErrors(formErrors)
  }, [formErrors])

  function handleChange(e) {
    // console.log(e.target.name);
    const { name, value } = e.target
    let currentFormErrors = formErrors

    const aValue = value

    switch (name) {
      case 'name':
        if (!aValue || minMaxLength(aValue, 3)) {
          currentFormErrors[name] = {
            msg: 'Name should have minimum 3 characters',
            valid: 'true',
          }
        } else {
          delete currentFormErrors[name]
        }
        setUser({ ...user, name: aValue })
        break
      case 'emailAddress':
        if (!aValue || validEmail(aValue)) {
          currentFormErrors[name] = {
            msg: 'Email address is invalid',
            valid: 'true',
          }
        } else {
          delete currentFormErrors[name]
        }
        setUser({ ...user, emailAddress: aValue })
        break
      case 'comment':
        if (!aValue || minMaxLength(aValue, 15)) {
          currentFormErrors[name] = {
            msg: 'Comment should have minimum 20 characters',
            valid: 'true',
          }
        } else {
          delete currentFormErrors[name]
        }
        setUser({ ...user, comment: aValue })
        break
      case 'subject':
        if (!aValue || minMaxLength(aValue, 5)) {
          currentFormErrors[name] = {
            msg: 'Subject should have minimum 5 characters',
            valid: 'true',
          }
        } else {
          delete currentFormErrors[name]
        }
        setUser({ ...user, subject: aValue })
        break
      default:
        break
    }

    setFormErrors(currentFormErrors)
    console.log(currentFormErrors)
  }

  function sendEmail(data) {
    const templateParams = {
      name: data.name,
      notes: data.comment,
      email: data.emailAddress,
      subject: data.subject,
    }

    emailjs
      .send(eData.SERVICE_ID, eData.TEMPLATE_ID, templateParams, eData.USER_ID)
      .then(
        response => {
          Swal.fire({
            icon: 'success',
            title: 'Thank you for contacting ISRID!',
            text: 'We will get back to you as soon as possible',
            confirmButtonText:'Okey!',
            confirmButtonColor: '#00c851',
          })
          setUser({})
          setFormErrors(errorDefaultData)
          captchaLoad()
          console.log('SUCCESS!', response.status, response.text)
        },
        err => {
          console.log('FAILED...', err)
        }
      )
  }

  function submitHandler(e) {
    e.preventDefault()

    if (!(Object.keys(formErrors).length === 0) || !verify) {
      alert('Please fill the form')
    } else {
      console.log('e', user)
      return sendEmail(user)
      callContactApi(function(flag) {
        if (flag) {
          setUser({})
          setFormErrors(errorDefaultData)
        }
        captchaLoad()
      })
    }

    ///e.target.className += " was-validated";
    //handleChange(e);
  }

  function callContactApi(callback) {
    axios({
      method: 'post',
      url: AXIOSLINK +'contact-user-data',
      data: user,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(function(response) {
        if (response.status == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Thank you!',
            text: 'Will get back to you soon..',
            confirmButtonText:'Okey!',
            confirmButtonColor: '#00c851',
          })
          callback(true)
        }
        callback(false)
      })
      .catch(function(response) {
        console.log(response)
        callback(false)
      })
  }

  return (
    <Layout location={''} crumbLabel="Contact">
      <div>
        <MDBContainer>
          <MDBCard className="contactCard" style={{ padding: '0px' ,backgroundColor:  "rgb(249, 249, 255)"}}>
            <MDBCardBody className="mx-4 mt-4">
              <MDBRow>
                <MDBCol md="12">
                  <form
                    className="needs-validation"
                    onSubmit={submitHandler}
                    noValidate
                  >
                    {allStrapiContacts.nodes.map((contact, key) => {
                      //debugger
                      const fieldType = contact.field_type
                      switch (fieldType) {
                        case LABEL:
                          return (
                            <p {...contact.field_attributes}>
                              
                              {contact.value}
                            </p>
                          )

                        case INPUT:
                          const inputData = {
                            ...contact.field_attributes,
                            onChange: handleChange,
                            value: user[contact.field_attributes.name] || '',
                            noValidate: 'noValidate',
                          }

                          return (
                            <CInput
                              fieldData={inputData}
                              formErrors={formErrors}
                            />
                          )

                        case BUTTON:
                          const buttonData = {
                            ...contact.field_attributes,
                          }
                          return <CButton fieldData={buttonData} />
                        case TITLELABLE:
                          return (
                            <MDBCardTitle {...contact.field_attributes}>
                              <div className="header ">
                                <MDBRow className="d-flex justify-content-center">
                                  <h2 className=" mb-3  font-weight-bold" style={{fontSize:"28px"}}>
                                    {contact.value}
                                  </h2>
                                </MDBRow>
                              </div>
                            </MDBCardTitle>
                          )

                        case CAPTCHA:
                          return (
                            <CaptchaComp
                              captchaCallback={captchaCallback}
                              loadCallBack={loadCallBack}
                            />
                          )

                        default:
                          return ''
                      }
                    })}
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBContainer>
      </div>
    </Layout>
  )

  function captchaLoad() {
    if (verify) {
      //alert('valid Captcha')
      load.loadFunc()
      setVerify(false)
    }
  }

  function captchaCallback(flag) {
    setVerify(flag)
  }

  function loadCallBack(loadFunc) {
    setLoad(loadFunc)
  }
}
export default Contact
export function ErrorList({ formErrors }) {
  return (
    <ul>
      
      {Object.entries(formErrors || {}).map(([prop, value]) => {
        return (
          <li className="error-message" key={prop}>
            
            {value}
          </li>
        )
      })}
    </ul>
  )
}
export const query = graphql`
  {
    allStrapiContacts(sort: { fields: [field_order], order: ASC }) {
      nodes {
        id
        field_type
        strapiId
        type_id
        field_order
        value
        field_attributes {
          className
          color
          id
          name
          value
          label
          float
          type
          rows
          required
        }
        style {
          textAlign
        }
      }
    }
  }
`
