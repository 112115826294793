import React from 'react'
import { MDBInput } from "mdbreact";



export default function CInput({ fieldData, formErrors, valid }) {

    const { value } = fieldData;
    const errorValue = {
        ...formErrors
    }

    let inputClass = (errorValue[fieldData.name] === undefined) ? "form-control is-valid" : ((errorValue && errorValue[fieldData.name].valid === "true") ? "form-control is-invalid" : "form-control");


    return (



        <div>



            <label
                htmlFor={fieldData.id}
                className="grey-text"
                style={{ marginBottom: "0px" }}
            >
                {fieldData.label}
                {fieldData.required && <span className="text-danger">*</span>}
            </label>

            {fieldData.type === "textarea" &&
                <textarea
                    {...fieldData}
                    className={inputClass}
                />}
            {(fieldData.type === "text" || fieldData.type === "email" || fieldData.type === "tel") &&
                <input
                    
                    {...fieldData}
                    className={inputClass}
                    style={{ marginBottom: "5px" }}
                />}

            <div className="invalid-feedback">
                {errorValue && errorValue[fieldData.name] && errorValue[fieldData.name].msg}
            </div>


        </div>

    )



}



