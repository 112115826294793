import React, { Component } from 'react'
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from 'react-simple-captcha'
import { MDBInput } from 'mdbreact'

class CaptchaComp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userText: '',
      valid: false,
      captchaReload: this.captchaReload.bind(),
    }
  }

  componentDidMount() {
    loadCaptchaEnginge(6, 'red', 'black')
    this.props.loadCallBack({
      loadFunc: this.state.captchaReload,
    })
  }

  doSubmit = e => {
    let user_captcha = e.target.value
    console.log('doSubmitValue==>' + user_captcha)
    let isValid = false;
    ///let isValid = validateCaptcha(user_captcha, false);
    //let user_captcha = document.getElementById('user_captcha_input').value;
    if (user_captcha && user_captcha.length > 5) {
      //this.props.captchaCallback({ flag: true, loadFunc: loadCaptchaEnginge })
      if (validateCaptcha(user_captcha, false) == true) {
        //loadCaptchaEnginge(6);
        //document.getElementById('user_captcha_input').value = "";
        isValid = true;
        this.props.captchaCallback(true)

      } else {
        isValid = false;
        //document.getElementById('user_captcha_input').value = "";
        this.props.captchaCallback(false)
      }
    }
    this.setState({
      userText: user_captcha,
      valid: isValid,

    })
  }

  captchaReload = () => {
    loadCaptchaEnginge(6, 'red', 'black')
    this.setState({
      userText: '',
      valid: false,
    })
  }

  render() {
    return (
      <div>
        <div class="row">
          <div class="col-md-4">
            <label
              htmlFor="user_captcha_input"
              className="grey-text"
            >
            </label>
            <input
              placeHolder="Enter Captcha Value"
              id="user_captcha_input"
              name="user_captcha_input"
              className={"form-control" + (!this.state.valid ? " is-invalid" : " is-valid")}
              value={this.state.userText}
              onChange={e => {
                this.doSubmit(e)
              }}
            />
            <div className="invalid-feedback">
              Enter the valid captcha
            </div>
          </div>
          <div class="col-md-3">
            <div>
              <LoadCanvasTemplateNoReload reloadText="Reload" />
            </div>


          </div>
          <div class="col-md-4 captcha-reload">
            <i
              class="fas fa-redo fa-lg"
              onClick={this.captchaReload.bind()}
            ></i>
          </div>
        </div>
        <div className="col" style={{ visibility: 'hidden' }}>
          <div>
            <button class="btn btn-primary" onClick={() => this.doSubmit()}>
              Submit
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default CaptchaComp